import { Fragment } from "react";

export function nl2br(text: string) {
  return Array.from(
    (function* () {
      let index = 0;
      for (const part of text.split(/\r?\n/g)) {
        if (index) yield <br key={index++} />;
        yield <Fragment key={index++}>{part}</Fragment>;
      }
    })(),
  );
}
